import React from 'react'
import PropTypes from 'prop-types'
import { useForm, SubmitHandler } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { sha256 } from 'js-sha256'
import { Person, Email, Briefcase } from '@corabank/icons'
import { validateEmail, validateCNPJ } from './validate'
import { splitName, getParams } from './utils'

import dispatchConversionsAPI from '../../../utils/dispatchConversionsAPI'
import { hasErrors } from '../../../utils/formHelpers';
import axios from '../../../utils/axios'

import { AxiosError } from 'axios'

import Button from '../Button'
import * as Styled from './styled'


const Form = ({ setSuccess, pageType }: FormProps): JSX.Element => {
  const { handleSubmit, register, errors, clearErrors } = useForm()

  const dispatchEventConversionsAPI = (data: FormValues) => {
    dispatchConversionsAPI('CompleteRegistration', {
      user_data: {
        em: [sha256(data.email)],
      },
    })
  }

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    const name = splitName(data.fullname)
    const dataSend = {
      submittedAt: +new Date(),
      fields: [
        {
          name: 'firstname',
          value: name.firstname,
        },
        {
          name: 'lastname',
          value: name.lastname,
        },
        {
          name: 'cnpj',
          value: data.cnpj,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'privacy_policy_consent',
          value: data.privacy_policy_consent,
        },
        {
          name: 'pagina_de_conversao',
          value: pageType,
        },
        {
          name: 'utm_source',
          value: getParams('utm_source'),
        },
        {
          name: 'utm_medium',
          value: getParams('utm_medium'),
        },
        {
          name: 'utm_campaign',
          value: getParams('utm_campaign'),
        },
        {
          name: 'utm_term',
          value: getParams('utm_term'),
        },
        {
          name: 'utm_content',
          value: getParams('utm_content'),
        },
        {
          name: 'test_ab_origin',
          value: 'default',
        },
      ],
    }

    axios
      .post(
        'https://api.hsforms.com/submissions/v3/integration/submit/6975423/7073029b-c707-49d3-b1a7-daf14b796144',
        dataSend
      )
      .then(() => {
        setSuccess(true)
        dispatchEventConversionsAPI(data)
      })
      .catch((error: AxiosError) => {
        console.log(error)
      })
  }

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Styled.InputWrapper>
        <Styled.Icon>
          <Person size="medium" color="#3B3B3B" />
        </Styled.Icon>
        <Styled.Input
          type="text"
          name="fullname"
          id="fullname"
          placeholder="Nome completo"
          required
          ref={register({
            required: 'Campo requerido',
          })}
          onClick={() => clearErrors('fullname')}
          className={hasErrors(errors.fullname)}
        />
        {hasErrors(errors.fullname, 'message') && (
          <Styled.Error>{hasErrors(errors.fullname, 'message')}</Styled.Error>
        )}
      </Styled.InputWrapper>

      <Styled.InputWrapper>
        <Styled.Icon>
          <Email size="medium" color="#3B3B3B" />
        </Styled.Icon>
        <Styled.Input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          required
          ref={register({
            required: 'Campo requerido',
            validate: (value: string) => validateEmail(value),
          })}
          onClick={() => clearErrors('email')}
          className={hasErrors(errors.email)}
        />
        {hasErrors(errors.email, 'message') && <Styled.Error>{hasErrors(errors.email, 'message')}</Styled.Error>}
        {hasErrors(errors.email, 'type') === 'validate' && (
          <Styled.Error>Email inválido</Styled.Error>
        )}
      </Styled.InputWrapper>

      <Styled.InputWrapper>
        <Styled.Icon>
          <Briefcase size="medium" color="#3B3B3B" />
        </Styled.Icon>
        <InputMask mask="99.999.999/9999-99">
          <Styled.Input
            type="text"
            name="cnpj"
            id="cnpj"
            placeholder="CNPJ"
            required
            ref={register({
              required: 'Campo requerido',
              validate: (value: string) => validateCNPJ(value),
            })}
            onClick={() => clearErrors('cnpj')}
            className={hasErrors(errors.cnpj)}
          />
        </InputMask>
        {hasErrors(errors.cnpj, 'message') && <Styled.Error>{hasErrors(errors.cnpj, 'message')}</Styled.Error>}
        {hasErrors(errors.cnpj, 'type') === 'validate' && (
          <Styled.Error>CNPJ inválido</Styled.Error>
        )}
      </Styled.InputWrapper>

      <Styled.CheckboxWrapper>
        <Styled.InputCheckbox
          type="checkbox"
          required
          name="privacy_policy_consent"
          id="privacy_policy_consent"
          ref={register({
            required: 'Campo requerido',
          })}
          onClick={() => clearErrors('privacy_policy_consent')}
          className={hasErrors(errors.privacy_policy_consent)}
        />
        <Styled.LabelCheckbox htmlFor="privacy_policy_consent">
          Estou de acordo com a{' '}
          <a
            href="https://www.cora.com.br/politica-privacidade/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de Privacidade
          </a>{' '}
          da Cora.
          <br />
          Você pode alterar suas permissões de comunicação a qualquer tempo.
        </Styled.LabelCheckbox>
        {hasErrors(errors.privacy_policy_consent, 'message') && (
          <Styled.Error>{hasErrors(errors.privacy_policy_consent, 'message')}</Styled.Error>
        )}
      </Styled.CheckboxWrapper>

      <Button type="submit" fluid>
        Confirmar
      </Button>
    </Styled.Form>
  )
}

Form.propTypes = {
  setSuccess: PropTypes.func.isRequired,
}

export default Form
