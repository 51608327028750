import { cnpj, cpf } from 'cpf-cnpj-validator'

const validateEmail = (email: string): boolean => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regex.test(email)
}

function validatePhone(phone: string): boolean {
  const regex = new RegExp(
    '^\\([0-9]{2}\\) ((3[0-9]{3}-[0-9]{4})|(9[0-9]{4}-[0-9]{4}))$'
  )

  return regex.test(phone)
}

function validateCNPJ(value: string): boolean {
  const cnpjSanitazed = value.replace(/[^\d]+/g, '')

  return cnpj.isValid(cnpjSanitazed)
}

function validateCPF(value: string): boolean {
  const cpfSanitazed = value.replace(/[^\d]+/g, '')

  return cpf.isValid(cpfSanitazed)
}

export { validateEmail, validatePhone, validateCNPJ, validateCPF }
