import styled from 'styled-components';

interface SideSectionStyledProps {
  open: boolean
}

interface ProgressBarProps {
  progressPercent: string
}

export const BackgroundOverlay = styled.div<SideSectionStyledProps>`
  background-color: rgba(0, 0, 0, .8);
  height: 100vh;
  position: fixed;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  transition: all .3s ease-in-out;
`;

export const Container = styled.div<SideSectionStyledProps>`
  background-color: #FFF;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100vw;
  overflow-y: scroll;
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};

  @media screen and (min-width: 768px) {
    width: 70%;
    right: 0;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    left: initial;
    transition: all .4s ease-in-out;
    opacity: 1;
    visibility: visible;
  }

  @media screen and (min-width: 1280px) {
    width: 50%;
  }
`;

export const Content = styled.div`
  padding: 75px 24px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 24px;
    padding: 45px 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 45px 0;
  }
`;

export const Header = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    grid-column: 2/8;
  }
`

export const ProgressBarWrapper = styled.div`
  height: 4px;
  width: 100%;
  background-color: #DEE4E9;
  border-radius: 4px;
`

export const ProgressBar = styled.div<ProgressBarProps>`
  height: 100%;
  width: ${({ progressPercent }) => progressPercent};
  background-color: #1EA4F3;
  border-radius: 4px;
`

export const FormWrapper = styled.div`
  @media screen and (min-width: 768px) {
    grid-column: 2/8;
  }
`;

export const Close = styled.img`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 20px;
  width: 40px;
  height: 40px;

  @media screen and (min-width: 768px) {
    top: 28px;
  }
`;

export const Title = styled.h4`
  color: #3B3B3B;
  font-size: 32px;
  line-height: 40px;
`;

export const Highlight = styled.div`
  margin: 16px 0 32px;
  font-size: 16px;
  line-height: 26px;
  color: #3B3B3B;

  span {
    color: #1EA4F3;
    font-weight: 500;
  }

  b {
    font-weight: 500;
  }
`;

export const Text = styled.small`
  color: #3B3B3B;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;

  a {
    color: #FE517B;
    text-decoration: none;
  }
`;

export const TextDesktop = styled(Text)`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    width: 100%;
  }
`

export const TextMobile = styled(Text)`
  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) {
    grid-column: 2/8;
  }

  & > Button {
    margin-top: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
`

export const QRCodeWrapper = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    margin-top: 48px;
  }
`

export const QRCode = styled.picture`
  width: 200px;
  height: 200px;
  flex: 0 0 200px;
  border-radius: 50%;
  background-color: #F0F4F8;
  margin-right: 24px;
  padding: 40px;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (min-width: 1280px) {
    width: 293px;
    height: 293px;
    flex: 0 0 293px;
    padding: 62px;
  }
`

export const QRCodeText = styled.span`
  font-size: 16px;
  line-height: 26px;
  color: #3B3B3B;

  b {
    color: #FE517B;
  }
`
