import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

interface LinkButtonProps {
  children: ReactNode
  fluid?: boolean
  href: string
  rel?: string
  size?: 'small' | 'large'
  target?: string
  mobileShadow?: boolean
}

const LinkButton: FC<LinkButtonProps> = ({
  children,
  fluid = false,
  href,
  rel = '',
  size = 'small',
  target = '',
  mobileShadow = false,
}: LinkButtonProps) => {
  return (
    <Styled.LinkButton
      fluid={fluid}
      size={size}
      href={href}
      rel={rel}
      target={target}
      mobileShadow={mobileShadow}
    >
      {children}
    </Styled.LinkButton>
  )
};

export default LinkButton;
