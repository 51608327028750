import React, { useState } from 'react'
import QRCode from 'qrcode.react'

import LinkButton from '../../../components/LinkButton'
import Form from '../../../components/Form'

import * as Styled from './styled'

import CloseIcon from '../../../images/close.svg'

const SideSection = ({ toggleForm, setToggleForm, idFinishForm, pageType }: SideSectionProps): JSX.Element => {
  const [success, setSuccess] = useState<boolean>(false)

  return (
    <>
      <Styled.Container open={toggleForm}>
        <Styled.Content>
          <Styled.Header>
            <Styled.ProgressBarWrapper>
              <Styled.ProgressBar progressPercent={success ? '50%' : '10%'} />
            </Styled.ProgressBarWrapper>

            <Styled.Close src={CloseIcon} alt="Fechar Formulário" onClick={() => setToggleForm(false)} />
          </Styled.Header>
          {!success && (
            <Styled.FormWrapper>
              <Styled.Title>Abra sua conta gratuita</Styled.Title>
              <Styled.Highlight>
                <span>Importante:</span>{' '}você precisa de um <b>CNPJ ativo</b> para abrir a sua conta.
              </Styled.Highlight>
              <Form setSuccess={setSuccess} pageType={pageType} />
            </Styled.FormWrapper>
          )}

          {success && (
            <Styled.Success>
              <Styled.Title id={idFinishForm}>Quase lá! Falta pouco pra você abrir sua conta.</Styled.Title>
              <Styled.TextDesktop>
                Aponte a câmera do celular para o QR code e <a href={`https://app.adjust.com/4uz2fax?campaign=${pageType}&engagement_type=fallback_click`} target="_blank" rel="noopener noreferrer">baixe o app</a>.
              </Styled.TextDesktop>
              <Styled.TextMobile>
                Agora é só baixar o App para concluir sua solicitação. Também enviaremos um e-mail para você acompanhar seu status de abertura de conta!
              </Styled.TextMobile>
              <Styled.QRCodeWrapper>
                <Styled.QRCode>
                  <QRCode
                    value={`https://app.adjust.com/4uz2fax?campaign=${pageType}&engagement_type=fallback_click`}
                    size={400}
                    bgColor={"#F0F4F8"}
                  />
                </Styled.QRCode>
                <Styled.QRCodeText>
                  <b>Importante:</b> se a opção de leitura de QR não for compatível com seu celular, você pode clicar no botão abaixo, também enviaremos um e-mail para você.
                </Styled.QRCodeText>
              </Styled.QRCodeWrapper>
              <Styled.ButtonWrapper>
                <LinkButton
                  href={`https://app.adjust.com/4uz2fax?campaign=${pageType}&engagement_type=fallback_click`}
                  target="_blank"
                  rel="noopener noreferrer"
                  fluid
                >
                  Baixar o App
                </LinkButton>
              </Styled.ButtonWrapper>
            </Styled.Success>
          )}
        </Styled.Content>
      </Styled.Container>
      <Styled.BackgroundOverlay open={toggleForm} onClick={() => setToggleForm(false)} />
    </>
  )
}

export default SideSection
