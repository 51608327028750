import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  & > button {
    margin-top: 32px;
  }
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;

  &:not(:first-child) {
    margin-top: 16px;
  }
`

export const Input = styled.input`
  border: 1px solid #F0F4F8;
  background: #F0F4F8;
  border-radius: 5px;
  color: #3B3B3B;
  font-size: 1.6rem;
  line-height: 24px;
  padding: 20px 16px 20px 56px;
  width: 100%;
  height: 64px;

  &.error {
    border-color: #E74C4C;
  }
`;

export const Icon = styled.div`
  height: 24px;
  left: 18px;
  position: absolute;
  top: 20px;
  z-index: 1;
  width: 24px;
`

export const Error = styled.span`
  color: #E74C4C;
  font-size: 1.4rem;
  line-height: 20px;
  margin-top: 4px;
  display: block;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  span {
    display: inline-block;
  }
`

export const InputCheckbox = styled.input`
  display: none;

  &:checked {
    & + label {
      &:before {
        background-color: #FF3F6E;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &:disabled {
    & + label {
      color: #C8CCD0;
    }
  }
`

export const LabelCheckbox = styled.label`
  color: #3B3B3B;
  font-size: 1.4rem;
  height: 24px;
  line-height: 24px;
  margin: 20px 0;
  padding-left: 40px;
  position: relative;
  display: inline-block;

  b {
    font-weight: 800;
  }

  a {
    color: #FF3F6E;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
  }

  &:before {
    background-color: #DFE5EA;
    border-radius: 4px;
    content: '';
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 24px;
  }

  &:after {
    border-color: #fff;
    border-style: solid;
    border-width: 0 0 2px 2px;
    content: '';
    height: 8px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    transition: all 0.3s ease-in-out;
    width: 13px;
  }
`
