import styled from 'styled-components';

interface LinkButtonStyledProps {
  fluid: boolean
  size: 'small' | 'large'
  mobileShadow: boolean
}

export const LinkButton = styled.a<LinkButtonStyledProps>`
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #FE517B;
  border: none;
  border-radius: 30px;
  color: #FFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  height: 48px;
  padding: 12px 32px;
  text-decoration: none;
  transition: all .3s ease-in-out;
  width: 100%;
  box-shadow: ${({ mobileShadow }) => (mobileShadow ? '0px 24px 32px rgba(32, 32, 32, 0.2)' : 'none')};

  @media screen and (min-width: 768px) {
    width: ${({ fluid }) => (fluid ? '100%' : 'initial')};
    font-size: 16px;
    line-height: 24px;
    box-shadow: none;
  }

  @media screen and (min-width: 1200px) {
    height: ${({ size }) => (size === 'small' ? '48px' : '64px')};
    font-weight: 800;
    font-size: ${({ size }) => (size === 'small' ? '16px' : '22px')};
    line-height: ${({ size }) => (size === 'small' ? '22px' : '32px')};
    padding: 16px 32px;
  }

  &:hover  {
    background-color: #DF365F;
  }

  *:disabled {
    background-color: #C7CBCF;
  }
`;
