export const splitName = (fullname: string): { firstname: string, lastname: string } => {
  const firstname = fullname.split(' ').slice(0, 1).join(' ');
  const lastname = fullname.split(' ').slice(-1).join(' ');

  return {
    firstname,
    lastname
  }
}

export const getParams = (params: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(params) || ''
}
