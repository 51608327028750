interface FormError {
  type: string
  message?: string
}

export const hasErrors = (
  errors: FormError,
  result?: 'type' | 'message'
): undefined | string => {
  if (!errors) {
    if (!result) {
      return ''
    }
    return undefined
  }

  if (!result) {
    return 'error'
  }
  return errors[result]
}
